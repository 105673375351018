<template>
  <div class="text-center ma-1">
    <v-img
      width="56"
      @error="() => (logoSrcError = true)"
      v-if="!logoSrcError"
      :src="getLogo"
      class="mx-auto"
    ></v-img>
    <v-icon size="56" class="ma-0" v-if="logoSrcError">{{
      getNameInit
    }}</v-icon>
    <div v-if="!!profileName">{{ profileName }}</div>
  </div>
</template>

<script>
export default {
  name: "ProfileLogo",
  props: {
    profileUuid: String,
    profileName: String,
    path: String,
  },
  data() {
    return {
      logoSrcError: false,
    };
  },
  computed: {
    getLogo() {
      return `${this.getApiBaseUrl}/files/image?path=${this.path}/_${this.profileUuid}.png`;
    },
    getName() {
      return this.profileName || null;
    },
    getNameInit() {
      if (!this.profileName) {
        return "mdi-circle";
      }

      return `mdi-alpha-${this.profileName
        .substring(0, 1)
        .toLowerCase()}-circle`;
    },
  },
};
</script>
