<template>
  <v-card
    hover
    class="shadow"
  >
    <v-sheet
      class="card-title" 
      @click="goToOrderDetails"
    >
      <div class="rounded pa-2 grey lighten-5 d-flex">
        <profile-logo
          class=""
          :profile-name="order.storeName"
          :profile-uuid="order.storeUuid"
          path="stores/logos"
        />
        <v-icon v-if="audiance === 'Driver'">mdi-arrow-right-thin</v-icon>
        <profile-logo
          v-if="audiance === 'Driver'"
          :profile-name="order.customerName"
          :profile-uuid="order.customerUuid"
          path="avatars"
        />
        <v-divider vertical />
        <div class="pa-2">
          <div class="d-flex align-start">
            <v-icon>mdi-map-marker</v-icon>
            <span>{{ getAddressString(order) }}</span>
          </div>
          <div><v-icon>mdi-timer</v-icon> {{ order.timeString }}</div>
          <div>
            <v-icon>mdi-pound-box</v-icon> Order No. {{ order.id }}
          </div>
        </div>
        <v-spacer />
        <div class="align-self-center"><v-icon x-large>mdi-chevron-right</v-icon></div>
      </div>
    </v-sheet>
    <!-- <v-divider style="border-stye: dashed" /> -->
    <v-progress-linear
      :value="stepValue(order.activeTrackStatus, order.scanned)"
      :color="$display.getStatusColor(order.activeTrackStatus)"
      :buffer-value="discontinued(order.activeTrackStatus) ? 100 : 10"
      :stream="inProgress(order.activeTrackStatus)"
    ></v-progress-linear>
    <v-row no-gutters>
      <v-col cols="12" sm="3" md="2">
        <div
          @click="audiance === 'Driver' && order.activeTrackStatus !== 'LOADING' ? changeStatus() : goToOrderDetails()"
          :class="`text-center ${$display.getStatusColor(
            order.activeTrackStatus
          )}`"
          :style="`height: 56px; padding-top: 15px; color: ${$display.getStatusTextColor(
            order.activeTrackStatus
          )};`"
        >
          {{ order.activeTrackStatus }}
          <v-icon
            v-if="this.audiance === 'Driver' && order.activeTrackStatus !== 'LOADING'"
            :color="$display.getStatusTextColor(order.activeTrackStatus)">mdi-dots-horizontal</v-icon>
        </div>
      </v-col>
      <v-col cols="12" sm="9" md="10">
        <v-stepper style="box-shadow: none" flat class="ma-0 pa-0">
          <v-stepper-header v-if="audiance === 'Driver'">
            <v-stepper-step :complete="completedStep(order.activeTrackStatus, 'DELIVERY')" step="1">
              Delivery
            </v-stepper-step>
            <v-divider />
            <v-stepper-step :complete="completedStep(order.activeTrackStatus, 'NEAR')" step="2">
              Near
            </v-stepper-step>
            <v-divider />
            <v-stepper-step :complete="completedStep(order.activeTrackStatus, 'DELIVERED')" step="3">
              Delivered
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-header v-if="audiance === 'Customer'">
            <v-stepper-step :complete="completedStep(order.activeTrackStatus, 'PREPARING')" step="1">
              Preparing
            </v-stepper-step>
            <v-divider />
            <v-stepper-step :complete="
            completedStep(order.activeTrackStatus, 'DISPATCHED') ||
            completedStep(order.activeTrackStatus, 'DELIVERY') ||
            completedStep(order.activeTrackStatus, 'NEAR')" step="2">
              Delivering
            </v-stepper-step>
            <v-divider />
            <v-stepper-step :complete="completedStep(order.activeTrackStatus, 'DELIVERED')" step="3">
              Delivered
            </v-stepper-step>
          </v-stepper-header>
        </v-stepper>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import ProfileLogo from "@/common/components/ProfileLogo";
import OrderStatusDialog from "@/views/orders/OrderStatusDialog";
import mixin from "@/views/orders/orders.mixin";
import { mapActions } from "vuex";

export default {
  name: "OrderCard",
  components: {
    ProfileLogo,
    OrderStatusDialog,
  },
  mixins: [mixin],
  props: {
    order: Object,
    audiance: {
      type: String,
      validator: function(value) {
        return ['Customer', 'Driver'].includes(value);
      }
    }
  },
  methods: {
    ...mapActions("orders", ["updateOrderStatus", "setOrderProps"]),
    ...mapActions("ui", ["showDialog", "closeDialog"]),
    changeStatus() {
      const dialog = {
        // title: this.$t("order.changeStatus"),
        component: OrderStatusDialog,
        params: {
          status: this.order.activeTrackStatus,
        },
        width: 350,
        noGutter: true,
        hideToolbar: true,
        actions: [
          {
            text: this.$t("general.okay"),
            click: ({ status }) => {
              if(status) {
                const params = {
                    orderUuid: this.order.uuid,
                    driverUuid: this.order.driverUuid,
                    status: status,
                    storeId: this.order.storeId,
                  };
  
                this.updateOrderStatus(params);//.then(() => {
                  // this.setOrderProps({
                  //   id: this.order.id,
                  //   stateCollection: "activeOrders",
                  //   props: {
                  //     status,
                  //   }
                  // });
                // });
                this.closeDialog();
              }
            },
            color: "primary",
          },
          {
            text: this.$t("general.cancel"),
            click: () => this.closeDialog(),
            color: "primary",
          },
        ],
      };
      this.showDialog(dialog);
    },
    goToOrderDetails() {
      this.$$router.push({
          name: this.audiance === 'Driver' ? 'delivery.order' : 'order',
          params: { uuid: this.order.uuid },
        })
    },
    completedStep(current, status) {
      const statusValues = [
        ["CANCELLED"],
        ["NOT ACCEPTED"],
        ["RETURNING"],
        ["WAITING"],
        ["PREPARING"],
        ["LOADING"],
        ["DISPATCHED"],
        ["DELIVERY"],
        ["NEAR"],
        ["DELIVERED"],
      ];

      return (
        statusValues.indexOf(statusValues.find((f) => f.includes(current))) >=
        statusValues.indexOf(statusValues.find((f) => f.includes(status)))
      );
    },
    stepValue(status, scanned) {
      const statusValues = this.audiance === "Customer" ?
      [
        { name: "CANCELLED", step: 0 },
        { name: "NOT ACCEPTED", step: 0 },
        { name: "RETURNING", step: 0 },
        { name: "WAITING", step: 13 },
        { name: "PREPARING", step: 26 },
        { name: "LOADING", step: 39 },
        { name: "DISPATCHED", step: 62 },
        { name: "DELIVERY", step: 75 },
        { name: "NEAR", step: 87 },
        { name: "DELIVERED", step: 100 },
      ] : [
        { name: "LOADING", step: 0 },
        { name: "RETURNING", step: 0 },
        { name: "DISPATCHED", step: 25 },
        { name: "DELIVERY", step: 50 },
        { name: "NEAR", step: 75 },
        { name: "DELIVERED", step: 100 },
      ];

      const sv = statusValues.find((f) => f.name === status);
      return sv ? sv.step + (scanned ? 12 && status === 'PREPARING' : 0) : 0;
    },

    inProgress(status) {
      return ["WAITING", "PREPARING", "LOADING", "DISPATCHED", "DELIVERY", "NEAR"].includes(status);
    },

    discontinued(status) {
      return ["CANCELLED", "NOT ACCEPTED", "RETURNING"].includes(status);
    },
  },
};
</script>