<template>
  <v-container>
    <v-list dense class="ma-0 pa-0">
      <v-list-item-group v-model="getStatus">
        <v-list-item class="ma-0 pa-0" v-for="v in statusValues" :key="v">
          <v-list-item-content>
            <v-btn
              depressed
              :style="`color: ${$display.getStatusTextColor(v)};`"
              :color="$display.getStatusColor(v)">
                <v-icon v-if="v === params.status">mdi-play</v-icon>
                {{  v }}
              </v-btn>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-container>
</template>

<script>
export default {
  name: "OrderStatusDialog",
  model: {
    prop: "params",
    event: "input",
  },
  props: {
    params: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      statusValues: ["DISPATCHED", "DELIVERY", "NEAR", "DELIVERED", "RETURNING"],
    };
  },
  computed: {
    getStatus: {
      get() {
        return this.params.status;
      },
      set(value) {
        this.params.status = this.statusValues[value];
    }
  },
  }
};
</script>
